import { useCallback, useEffect, useMemo } from "react"
import { useLocation, useSearchParams } from "@remix-run/react"
import { produce } from "immer"
import { create } from "zustand"

import type { PageId } from "@/config/pages"
import { getPageIdFromPathname } from "@/config/pages"

/**
 * Type definitions for detail states
 * - Single state shows details for one item (customer, work order, or job)
 * - Multiple state shows details for multiple work orders only
 */
export interface DetailsSingleState {
	view: string
	id: string
}

export interface DetailsMultipleState {
	view: string
	ids: string[]
}

type DetailsState = DetailsSingleState | DetailsMultipleState

/**
 * Each page can have either a single or multiple detail state active
 * but never both simultaneously
 */
interface PageDetailsState {
	multiState: DetailsMultipleState | null
	singleState: DetailsSingleState | null
}

/**
 * Global store interface for managing detail states across multiple pages
 * Uses Record to allow dynamic page Ids as keys
 */
interface DetailsStore {
	pages: Record<string, PageDetailsState>
	open: (derivedPageId: PageId, state: DetailsState) => void
	close: (derivedPageId: PageId) => void
}

/**
 * Zustand store implementation
 * - Uses Immer for immutable state updates
 * - Determines state type by checking for 'ids' property
 * - Ensures only one state type is active at a time
 */
const useDetailsStore = create<DetailsStore>((set) => ({
	pages: {},
	open: (derivedPageId, state) => {
		set(
			produce((store) => {
				const isMulti = "ids" in state
				store.pages[derivedPageId] = {
					multiState: isMulti ? state : null,
					singleState: !isMulti ? state : null,
				} as PageDetailsState
			}),
		)
	},
	close: (derivedPageId) => {
		set(
			produce((store) => {
				store.pages[derivedPageId] = {
					multiState: null,
					singleState: null,
				}
			}),
		)
	},
}))

export const useDetailsPages = () => {
	return useDetailsStore((state) => state.pages)
}

interface ClosedDetailsState {
	isOpen: false
}

interface OpenSingleDetailsState {
	isOpen: true
	mode: "single"
	view: string
	id: string
}

interface OpenMultipleDetailsState {
	isOpen: true
	mode: "multiple"
	view: string
	ids: string[]
}

type DetailsStateReturn =
	| ClosedDetailsState
	| OpenSingleDetailsState
	| OpenMultipleDetailsState

/**
 * Main hook for managing detail states
 * Combines URL state for single items and store state for multiple items
 *
 * Key features:
 * - Single items use URL params for shareable/bookmarkable links
 * - Multiple items use store state since URL would get unwieldy
 * - Memoizes derived values to prevent unnecessary rerenders
 * - Provides unified API for both state types
 */
export function useDetailsState(pageId?: PageId): DetailsStateReturn & {
	open: (state: DetailsState) => void
	close: () => void
} {
	const { pathname } = useLocation()
	const derivedPageId = useMemo(() => {
		const id = getPageIdFromPathname(pathname) ?? pageId
		if (!id)
			throw new Error(
				"A page Id must be provided to useDetailsState, could not infer from pathname",
			)
		return id
	}, [pathname, pageId])
	const [searchParams, setSearchParams] = useSearchParams()
	const { pages, open: openStore, close: closeStore } = useDetailsStore()

	// Helper to manage URL params
	const updateUrlParams = useCallback(
		(params: { view?: string; id?: string }) => {
			const newParams = new URLSearchParams(searchParams)
			if (params.view && params.id) {
				newParams.set("view", params.view)
				newParams.set("id", params.id)
			} else {
				newParams.delete("view")
				newParams.delete("id")
			}
			setSearchParams(newParams, {
				replace: true,
			})
		},
		[searchParams, setSearchParams],
	)

	// Derive single state from URL params for shareable links
	const singleState = useMemo(() => {
		const view = searchParams.get("view")
		const id = searchParams.get("id")
		return view && id ? { view, id } : null
	}, [searchParams])

	// Get multi state from store since URLs would be too long
	const multiState = useMemo(
		() => pages[derivedPageId]?.multiState ?? null,
		[derivedPageId, pages],
	)

	useEffect(() => {
		// Sync store with URL state
		if (singleState) {
			// Open store if URL has state but store doesn't match
			if (
				!pages[derivedPageId]?.singleState ||
				pages[derivedPageId]?.singleState?.id !== singleState.id ||
				pages[derivedPageId]?.singleState?.view !== singleState.view
			) {
				openStore(derivedPageId, singleState)
			}
		} else {
			// Close store if URL has no state but store does
			if (pages[derivedPageId]?.singleState) {
				closeStore(derivedPageId)
			}
		}
	}, [openStore, closeStore, derivedPageId, pages, singleState])

	// Handle opening details with appropriate state management
	const openDetails = useCallback(
		(state: DetailsState) => {
			updateUrlParams("ids" in state ? {} : state)
			openStore(derivedPageId, state)
		},
		[openStore, derivedPageId, updateUrlParams],
	)

	// Handle closing by clearing only view and id params
	const closeDetails = useCallback(() => {
		updateUrlParams({})
		closeStore(derivedPageId)
	}, [closeStore, derivedPageId, updateUrlParams])

	if (multiState) {
		return {
			isOpen: true,
			mode: "multiple",
			view: multiState.view,
			ids: multiState.ids,
			open: openDetails,
			close: closeDetails,
		}
	}

	if (singleState) {
		return {
			isOpen: true,
			mode: "single",
			view: singleState.view,
			id: singleState.id,
			open: openDetails,
			close: closeDetails,
		}
	}

	return {
		isOpen: false,
		open: openDetails,
		close: closeDetails,
	}
}

export function isItemSelected(
	details: DetailsStateReturn,
	params: {
		view: string
		id: string
	},
) {
	if (!details.isOpen || details.view !== params.view) {
		return false
	}

	if (details.mode === "single") {
		return details.id === params.id
	}

	if (details.mode === "multiple") {
		return details.ids.includes(params.id)
	}

	return false
}
