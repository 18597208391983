import type { LucideIcon } from "lucide-react"
import {
	BoxesIcon,
	DollarSignIcon,
	FuelIcon,
	GemIcon,
	HammerIcon,
	HomeIcon,
	LayoutIcon,
	MapPinIcon,
	PaperclipIcon,
	ShieldIcon,
	SquareCheckIcon,
	TimerIcon,
	TruckIcon,
	UserIcon,
	UsersIcon,
	WrenchIcon,
} from "lucide-react"

interface InternalPageConfig {
	id: string
	label: string
	path: string
	icon: LucideIcon
	meta?: {
		title?: string
		description?: string
	}
}

interface InternalPageGroup {
	id: string
	label: string
	pages: InternalPageConfig[]
}

export const pagesConfig = [
	{
		id: "work",
		label: "Work",
		pages: [
			{
				id: "work.dashboard",
				label: "Dashboard",
				path: "/work/dashboard",
				icon: LayoutIcon,
				meta: {
					title: "Work Dashboard",
					description: "Overview of work activities and metrics",
				},
			},
			{
				id: "work.fuel",
				label: "Fuel",
				path: "/work/fuel",
				icon: FuelIcon,
			},
			{
				id: "work.inventory",
				label: "Inventory",
				path: "/work/inventory",
				icon: BoxesIcon,
			},
			{
				id: "work.work-orders",
				label: "Work Orders",
				path: "/work/work-orders",
				icon: WrenchIcon,
			},
			{
				id: "work.maintenance",
				label: "Preventive Maint.",
				path: "/work/maintenance",
				icon: SquareCheckIcon,
			},
		],
	},
	{
		id: "resources",
		label: "Resources",
		pages: [
			{
				id: "resources.customers",
				label: "Customers",
				path: "/resources/customers",
				icon: UserIcon,
			},
			{
				id: "resources.employees",
				label: "Employees",
				path: "/resources/employees",
				icon: UsersIcon,
			},
			{
				id: "resources.equipment",
				label: "Equipment",
				path: "/resources/equipment",
				icon: TruckIcon,
			},
			{
				id: "resources.service",
				label: "Service",
				path: "/resources/service",
				icon: WrenchIcon,
			},
		],
	},
	{
		id: "admin",
		label: "Admin",
		pages: [
			{
				id: "admin.equipment",
				label: "Equipment",
				path: "/admin/equipment",
				icon: TruckIcon,
			},
			{
				id: "admin.financial",
				label: "Financial",
				path: "/admin/financial",
				icon: DollarSignIcon,
			},
			{
				id: "admin.fuel",
				label: "Fuel",
				path: "/admin/fuel",
				icon: FuelIcon,
			},
			{
				id: "admin.locations",
				label: "Locations",
				path: "/admin/locations",
				icon: MapPinIcon,
			},
			{
				id: "admin.parts",
				label: "Parts",
				path: "/admin/parts",
				icon: HammerIcon,
			},
			{
				id: "admin.reports",
				label: "Reports",
				path: "/admin/reports",
				icon: PaperclipIcon,
			},
			{
				id: "admin.service",
				label: "Service",
				path: "/admin/service",
				icon: WrenchIcon,
			},
			{
				id: "admin.shop-clock",
				label: "Shop Clock",
				path: "/admin/shop-clock",
				icon: TimerIcon,
			},
			{
				id: "admin.users",
				label: "Users",
				path: "/admin/users",
				icon: UsersIcon,
			},
		],
	},
	{
		id: "dev",
		label: "Dev",
		pages: [
			{
				id: "dev.home",
				label: "Home",
				path: "/",
				icon: HomeIcon,
			},
			{
				id: "dev.components",
				label: "Components",
				path: "/components",
				icon: LayoutIcon,
			},
			{
				id: "dev.sentry",
				label: "Sentry",
				path: "https://lavavein.sentry.io/issues/?project=4508053803171840",
				icon: ShieldIcon,
			},
			{
				id: "dev.deployment",
				label: "Deployment",
				path: "https://dev.squarerigger.lavave.in/",
				icon: GemIcon,
			},
		],
	},
] as const satisfies InternalPageGroup[]

export type PageGroup = (typeof pagesConfig)[number]
export type PageConfig = (typeof pagesConfig)[number]["pages"][number]
export type PageId = (typeof pagesConfig)[number]["pages"][number]["id"]

export function getPageIdFromPathname(pathname: string): PageId | undefined {
	for (const group of pagesConfig) {
		const matchingPage = group.pages.find((page) => {
			// Skip external URLs
			if (page.path.startsWith("http")) return false
			// Exact match
			if (page.path === pathname) return true
			// Match dynamic routes by checking if pathname starts with page path
			// Only match if page path ends with / to avoid partial matches
			if (page.path.endsWith("/") && pathname.startsWith(page.path))
				return true
			return false
		})
		if (matchingPage) return matchingPage.id
	}
	return undefined
}
